<template>
  <c-main>
    <c-toolbar>
      <c-csc-logo />
      My&nbsp;CSC
    </c-toolbar>

    <c-page>
      <slot />
    </c-page>
  </c-main>
</template>

<script lang="ts" setup></script>

<style>
c-page {
  --c-page-max-width: 1800px;
}
</style>
